/* eslint-disable no-bitwise, consistent-return */
import React, { useState, useEffect, Fragment } from 'react';
import { isEmpty, get, isNumber } from 'lodash';
import Social from '../../../../components/social/social';
import PropTypes from 'prop-types';
import axios from 'axios';
// import EventModel from '../../../utils/tracking/models';

export const StickyBar = ({
  slug,
  post,
  activeTab,
  setActiveTab,
  location,
  scroll,
  externalData,
  hideTopBorder,
  hideSeparator,
  children,
  initialData = {},
  initialLists = [],
  overrideShareIcon,
  overrideSocialText,
  svgWidth,
  svgHeight
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialData);
  const [lists, setLists] = useState(initialLists);
  

  const handlePopup = e => {
    if (/^http/gi.test(e.currentTarget.value)) {
      window.location.href = e.currentTarget.value;
    }
  };

  // const handleNavListClick = (arr, position) => {
  //   const clickEvent = new EventModel(
  //     `BWI Bottom Sticky Nav- ${get(arr, 'category', 'no category')}`,
  //     'bottomNavClick',
  //     'click',
  //     `nav-${get(arr, 'category', 'no category')}`,
  //     `${position + 1} | ${arr.length}`
  //   );

  //   clickEvent.triggerTrackingEvent(
  //     'default-click',
  //     clickEvent.getTrackingObject()
  //   );
  // };

  const buildContent = type => {
    if(children) return children;

    switch (type) {
      case 'title':
        return (
          <a
            href={data.link_url ? `${data.link_url}` : `/${data.slug}`}
            target="_blank"
            style={{ color: data.text_color }}
            onClick={e => {
              if (location === 'lander') {
                e.preventDefault();
                scroll();
              }
            }}
          >
            {data.display_name}
          </a>
        );

      case 'custom':
      case 'categories':
        return (
          <Fragment>
            {lists.map((list, i) => (
              <div
                key={`box-${i}`}
                className={`bottomStickyNav-content_box bottomStickyNav-content_box${activeTab === i ? '--active' : ''}`}
                style={{
                  borderLeftColor: data.border_color,
                  color:
                    location === 'article' || activeTab === i
                      ? data.text_color
                      : '#CCC'
                }}
                onClick={() => {
                  setActiveTab(i)
                  if (scroll) {
                    scroll();
                  }
                }}
              >
                <a
                  style={{
                    color:
                      location === 'article' || activeTab === i
                        ? data.text_color
                        : '#CCC'
                  }}
                >
                  {list.category}
                </a>

                {data.display_category_index === i && (
                  <div className="hiddenDropdown">
                    <select onChange={e => handlePopup(e)}>
                      {list.data.map((category, j) => (
                        <option
                          value={category.url}
                          onClick={() => handleNavListClick(list, j)}
                        >
                          {category.title}
                        </option>
                      ))}
                    </select>
                    <button>▲</button>
                  </div>
                )}
              </div>
            ))}
          </Fragment>
        );

      default:
        return null;
    }
  };

  const DefaultShareIcon = ({ pathFillColor = '#000000' }) => (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width={svgWidth || '34.66'}
      height={svgHeight || '23.4'}
      viewBox="0 0 34.66 23.4"
    >
      <path
        style={{ fill: pathFillColor }}
        d="M30.553-8.851a23.706,23.706,0,0,0-7.446,3.16A27.918,27.918,0,0,0,19-2.432V-15.269a27.93,27.93,0,0,0,4.108,3.258,23.711,23.711,0,0,0,7.446,3.16"
        transform="translate(4.106 20.712)"
      />
      <path
        style={{ fill: pathFillColor }}
        d="M4-8.3H26.082v-1.964H4Z"
        transform="translate(0.875 21.177)"
      />
      <path
        style={{ fill: pathFillColor }}
        d="M316.027,407.7H296.973V384.3h19.055v7.275h-2V386.3H298.973v19.4h15.055V400.35h2Z"
        transform="translate(-296.973 -384.3)"
      />
    </svg>
  );

  return (
    <div
      className={`bottomStickyNav ${
        hideTopBorder ? 'bottomStickyNav--hideTopBorder' : ''
      }`}
      style={{
        backgroundColor: data.background_color,
        borderColor: data.border_color
      }}
    >
      <div className="bottomStickyNav-content">
        {buildContent(data.display_type)}
        <div
          className={`bottomStickyNav-social ${hideSeparator ? 'hideSeparator' : ''}`}
          onClick={() => setOpen(!open)}
        >
          {overrideShareIcon || <DefaultShareIcon pathFillColor={data?.background_color} />}
          {open && (
            <div className="bottomStickyNav__icons">
              <Social
                modifier="StickyBar"
                disableStyle={true}
                hideRecirc={true}
                text={overrideSocialText || get(post, 'customFields.facebook_social_copy', post.title)}
                uri={
                  get(data, 'link_url', null) && !isNumber(get(post, 'id'))
                    ? `https://www.fastcompany.com${get(data, 'link_url')}`
                    : `https://www.fastcompany.com/${get(post, 'id')}/${get(
                        post,
                        'slug'
                      )}`
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StickyBar.propTypes = {
  slug: PropTypes.string.isRequired,
  post: PropTypes.shape({}).isRequired,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  location: PropTypes.string.isRequired,
  scroll: PropTypes.func,
  externalData: PropTypes.shape([]),
  hideTopBorder: PropTypes.bool
};

StickyBar.defaultProps = {
  activeTab: null,
  setActiveTab: null,
  scroll: null,
  externalData: [],
  hideTopBorder: false
};
